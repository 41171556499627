import DropdownMenu, { DropdownItem, DropdownItemGroup } from "@atlaskit/dropdown-menu";
import { Link } from "gatsby";
import React from "react";
import Icon from "react-icons-kit";
import Button, { LoadingButton } from '@atlaskit/button';
import { threeHorizontal } from 'react-icons-kit/entypo/threeHorizontal'
import { useTranslation } from "react-i18next";
import styled from 'styled-components';
import EditIcon from '@atlaskit/icon/glyph/edit';
import TrashIcon from '@atlaskit/icon/glyph/trash';

type Props = {
  items: { label?: string, linkTo?: string, onClick?: SendNotioFN, isDividor?: boolean, isIcon?: Boolean }[]

};

type SendNotioFN = () => void;


export const DropdownMenuCustom = (props: Props) =>
  <DropdownMenu trigger={({ triggerRef, ...props }) => (
    <Button
      {...props}
      iconBefore={<Icon icon={threeHorizontal} />}
      ref={triggerRef}
      style={{ padding: '0 10px' }}
    />)}
    placement="bottom-end"
  >
    <DropdownItemGroup>
      {props.items.map((e: any) => {
        if (!e.isDividor) {
          return <DropdownItem>
            <IconWrapper
              data-testid={"edit-user-button"}>
              {
                e.isIcon &&
                <EditIcon label="edit" size="small" />
              }
              {
                e.onClick && <div onClick={() => e.onClick()}>{e.label}</div>
              }
              {
                e.linkTo && <Link to={e.linkTo}>{e.label}</Link>
              }
            </IconWrapper>
          </DropdownItem>
        } else {
          return <Dividor />

        }
      })}
    </DropdownItemGroup>
  </DropdownMenu>

const Dividor = styled.hr`
  margin: 0;
`

const IconWrapper = styled.div`

  display: flex;
  justify-content: start;
  span {
    cursor: pointer;
  }
`;

